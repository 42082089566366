
import { defineComponent, onMounted, ref } from "vue";
import BaseService from "@/services/admin/BaseService";
import UserCard from "@/components/layout/home/UserCard.vue";
import CursosCard from "@/components/layout/home/CursosCard.vue";
import DisciplinasCard from "@/components/layout/home/DisciplinasCard.vue";
import RegimentoInternoCard from "@/components/layout/home/RegimentoInternoCard.vue";
import TurmaCard from "@/components/layout/home/TurmaCard.vue";
import Feed from "@/components/layout/home/Feed.vue";
import Plantao from "@/components/layout/home/Plantao.vue";
import Notificacao from "@/interfaces/Notificacao";
import Curso from "@/interfaces/Curso";
import PlantaoDuvida from "@/interfaces/PlantaoDuvida";
import Disciplina from "@/interfaces/Disciplina";
import store from "@/store";
import RegimentoInterno from "@/interfaces/RegimentoInterno";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import NotificacaoAvaliacao from "@/components/layout/home/notificacoes/NotificacaoAvaliacao.vue";
import NotificacaoPostagem from "@/components/layout/home/notificacoes/NotificacaoPostagem.vue";

export default defineComponent({
  components: {
    NotificacaoPostagem,
    NotificacaoAvaliacao,
    UserCard,
    CursosCard,
    DisciplinasCard,
    TurmaCard,
    Feed,
    Plantao,
    RegimentoInternoCard,
  },
  props: { user: { type: Object, required: true } },
  setup(props) {
    const loading = ref<boolean>(false);
    const urlImg = ref<string>(process.env.VUE_APP_STORAGE_BASE);
    const cursos = ref<Curso[]>();
    const disciplinas = ref<Disciplina[]>();
    const $q = useQuasar();
    const regimentoInternos = ref<RegimentoInterno[]>();
    const notificacoes = ref<Notificacao[]>();
    const notificacoesFixadas = ref<Notificacao[]>();
    const plantoes = ref<PlantaoDuvida[]>();
    const feed = ref();
    const dialog = ref(false);

    const getNotificacoes = (page = 1): void => {
      loading.value = true;
      let url = "notificacoes?page=" + page;
      if (store.getters.divisaoId) {
        url = url + "&divisaoId=" + store.getters.divisaoId;
      }
      BaseService.list(url)
        .then((res) => {
          if (res.data.data.length == 0) {
            feed.value.dontHaveUpdate = true;
          }
          if (notificacoes.value) {
            res.data.data.forEach((item: Notificacao) => {
              notificacoes.value?.push(item);
            });
          } else {
            notificacoes.value = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
    };

    const getNotificacoesFixadas = (): void => {
      loading.value = true;

      let url = `notificacoes/fixadas`;
      if (store.getters.divisaoId) {
        url = url + "?divisaoId=" + store.getters.divisaoId;
      }
      BaseService.list(url)
        .then((res) => {
          notificacoesFixadas.value = res.data;
          if (notificacoesFixadas.value) {
            dialog.value = true;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
    };

    const getPlantoesDuvidas = (): void => {
      loading.value = true;
      BaseService.list("aluno/plantao-duvidas")
        .then((res) => {
          plantoes.value = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
    };

    const getRegimentoInternos = (): void => {
      loading.value = true;
      BaseService.list("aluno/regimento-internos")
        .then((res) => {
          regimentoInternos.value = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
    };

    const getCursos = (): void => {
      loading.value = true;
      BaseService.list("aluno/meus-cursos")
        .then((res) => {
          cursos.value = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
    };

    const getDisciplinas = (): void => {
      loading.value = true;
      BaseService.list("professor/minhas-disciplinas")
        .then((res) => {
          disciplinas.value = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
    };

    onMounted(() => {
      if (props.user.perfil.nome == "aluno") {
        getCursos();
        getPlantoesDuvidas();
        getRegimentoInternos();
      } else if (props.user.perfil.nome == "professor") {
        getPlantoesDuvidas();
        getDisciplinas();
      }
      getNotificacoesFixadas();
      // getNotificacoes();
    });

    return {
      loading,
      urlImg,
      getCursos,
      cursos,
      getNotificacoes,
      notificacoes,
      plantoes,
      getDisciplinas,
      disciplinas,
      getPlantoesDuvidas,
      getNotificacoesFixadas,
      notificacoesFixadas,
      feed,
      getRegimentoInternos,
      regimentoInternos,
      dialog,
    };
  },
});
