
import { defineComponent, ref } from "vue";
import NotificacaoPostagem from "./notificacoes/NotificacaoPostagem.vue";
import NotificacaoAula from "./notificacoes/NotificacaoAula.vue";
import NotificacaoAnexo from "./notificacoes/NotificacaoAnexo.vue";
import NotificacaoDuvida from "./notificacoes/NotificacaoDuvida.vue";
import NotificacaoAvaliacao from "./notificacoes/NotificacaoAvaliacao.vue";
import NotificacaoRedacao from "./notificacoes/NotificacaoRedacao.vue";
import NotificacaoMensagem from "./notificacoes/NotificacaoMensagem.vue";
import NotificacaoCorrecao from "./notificacoes/NotificacaoCorrecao.vue";

export default defineComponent({
  components: {
    NotificacaoAula,
    NotificacaoAnexo,
    NotificacaoDuvida,
    NotificacaoAvaliacao,
    NotificacaoRedacao,
    NotificacaoMensagem,
    NotificacaoCorrecao
  },
  props: {
    notificacoes: { type: Array },
    notificacoesFixadas: { type: Array },
  },
  setup(props, { emit }) {
    const dontHaveUpdate = ref<boolean>(false);

    const onLoad = (index: number, done: any) => {
      emit("atualizarNotificacoes", index);
      setTimeout(() => {
        done(dontHaveUpdate.value);
      }, 2000);
    };

    return {
      onLoad,
      dontHaveUpdate,
    };
  },
});
